// this is included in a <script> tag in them main index.html file
import "core-js/es/array/find-index"
import "core-js/es/array/for-each"
import "core-js/es/array/from"
import "core-js/es/object/assign"
import "core-js/es/object/entries"
import "core-js/es/set"
import "core-js/es/string/ends-with"
import "core-js/es/string/includes"
import "core-js/es/string/starts-with"
import "core-js/es/symbol"
import "core-js/features/array/fill"
import "core-js/features/map"
import "core-js/features/promise"
import "whatwg-fetch"
